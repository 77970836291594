import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// eslint-disable-line
import cn from './locales/zh-CN.json'
// eslint-disable-line
import hk from './locales/zh-HK.json'
// eslint-disable-line
import en from './locales/en-US.json'
// eslint-disable-line
import ja from './locales/ja-JP.json'
// eslint-disable-line
import ko from './locales/ko-KR.json'
// eslint-disable-line
import ru from './locales/ru-RU.json'
// eslint-disable-line
import id from './locales/id-ID.json'

const resources = {
  "zh-CN": {
    translation: cn
  },
  "zh-HK": {
    translation: hk
  },
  "en-US": {
    translation: en
  },
  "ja-JP": {
    translation: ja
  },
  "ko-KR": {
    translation: ko
  },
  "ru-RU": {
    translation: ru
  },
  "id-ID": {
    translation: id
  },
};

i18n.use(LanguageDetector) //嗅探当前浏览器语言 
.use(initReactI18next) 
  .init({
    resources,
    fallbackLng: "zh-CN",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    }
  })

export default i18n